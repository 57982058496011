function SmartContactLogoIcon() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="39" height="16" viewBox="0 0 39 16" fill="none">
        <g clip-path="url(#clip0_133_20)">
          <path
            d="M19.6539 10.6536C19.1951 10.4367 18.841 10.1316 18.591 9.73932C18.341 9.347 18.2157 8.89171 18.2157 8.37485V5.02081H19.1775V8.39257C19.1775 8.93976 19.3601 9.36939 19.7258 9.68147C20.0915 9.99355 20.5945 10.1498 21.2359 10.1498C21.8773 10.1498 22.3818 9.99355 22.751 9.68147C23.1197 9.36939 23.3038 8.93976 23.3038 8.39257V5.02081H24.2656V8.37485C24.2656 8.89218 24.1389 9.347 23.8859 9.73932C23.6323 10.1316 23.2782 10.4367 22.8229 10.6536C22.3677 10.871 21.8385 10.9792 21.2359 10.9792C20.6333 10.9792 20.1121 10.871 19.6539 10.6536Z"
            fill="black"
          />
          <path
            d="M28.1707 5.02081C29.3121 5.02081 30.1793 5.26479 30.7724 5.75226C31.3654 6.23974 31.6622 6.95067 31.6622 7.8841V8.01799C31.6622 8.95189 31.3654 9.66095 30.7724 10.1452C30.1793 10.6298 29.3121 10.8719 28.1707 10.8719H25.2179V5.02081H28.1707ZM30.6909 8.02685V7.87524C30.6909 7.19744 30.4776 6.6843 30.051 6.33677C29.6244 5.98877 28.9946 5.81477 28.1611 5.81477H26.1797V10.0785H28.1707C29.8508 10.0785 30.6909 9.39459 30.6909 8.02685Z"
            fill="black"
          />
          <path
            d="M36.3273 5.02081C37.0326 5.02081 37.5904 5.17709 38.0009 5.48916C38.4114 5.80124 38.6166 6.22201 38.6166 6.75148V6.8583C38.6166 7.38776 38.4114 7.80807 38.0009 8.12061C37.5904 8.43269 37.0326 8.58896 36.3273 8.58896H33.4032V10.8724H32.4414V5.02081H36.3273ZM37.2941 7.54963C37.528 7.38636 37.6453 7.15592 37.6453 6.8583V6.75148C37.6453 6.45432 37.528 6.22388 37.2941 6.06014C37.0602 5.89687 36.7312 5.81477 36.3082 5.81477H33.4032V7.79501H36.3082C36.7312 7.79501 37.0597 7.71337 37.2941 7.54963Z"
            fill="black"
          />
          <path
            d="M4.99097 8.00022C3.89402 6.97022 3.3694 5.51945 3.57209 4.07474C3.77479 2.6305 4.68096 1.35653 6.02482 0.627878C7.36868 -0.100773 8.99672 -0.200601 10.43 0.35685C11.8633 0.9143 12.9394 2.06605 13.3408 3.47344C12.4947 3.26072 11.6035 3.26072 10.7574 3.47344C9.91133 3.68616 9.1393 4.1046 8.52028 4.68631C7.34384 5.79094 6.16741 6.89558 4.99097 8.00022Z"
            fill="url(#paint0_linear_133_20)"
          />
          <path
            d="M4.99087 8.00023C6.1673 6.89559 7.34374 5.79095 8.52018 4.68631C9.13969 4.1046 9.91123 3.68617 10.7573 3.47345C11.6034 3.26073 12.4946 3.26073 13.3407 3.47345C13.1782 2.9048 12.9035 2.37021 12.5309 1.89719C12.4539 1.7997 12.3739 1.70547 12.2889 1.61404C12.2467 1.56879 12.204 1.524 12.1603 1.48015C12.1384 1.45823 12.1166 1.43677 12.0942 1.41531C12.0758 1.39759 12.0574 1.38033 12.0391 1.36307C11.9546 1.28563 11.8617 1.21799 11.7603 1.16061C11.6545 1.10043 11.5427 1.05285 11.426 1.01553C11.3644 0.995942 11.3018 0.979149 11.2382 0.96562C11.1716 0.951159 11.1051 0.939964 11.037 0.9311C10.9043 0.914307 10.7727 0.908243 10.6391 0.910575C10.5675 0.911975 10.496 0.915706 10.4244 0.921304C10.3494 0.927369 10.2744 0.936232 10.1999 0.946961C10.0379 0.970285 9.87894 1.00341 9.72096 1.04446C9.54608 1.09017 9.37468 1.14475 9.20577 1.20726C9.07312 1.25671 8.94246 1.31035 8.81329 1.3682C8.67568 1.43024 8.54104 1.49648 8.4079 1.56645C8.12074 1.71853 7.84402 1.88926 7.57923 2.07399C7.45602 2.15982 7.33579 2.24846 7.21755 2.34035C7.09683 2.43412 6.97908 2.53068 6.86432 2.63004C6.63182 2.8311 6.41173 3.04195 6.20258 3.26446C6.14793 3.32277 6.09378 3.38155 6.04062 3.44126C5.98796 3.5005 5.93679 3.55975 5.88611 3.62039C5.78725 3.73841 5.69186 3.85877 5.59995 3.98192C5.42706 4.21376 5.26908 4.45214 5.12649 4.70124C4.96652 4.98113 4.82642 5.27408 4.71862 5.5759C4.6277 5.8292 4.55865 6.0923 4.52337 6.3582C4.50102 6.52753 4.49207 6.69873 4.50251 6.86947C4.51145 7.02014 4.5353 7.16988 4.57802 7.31543C4.59789 7.3826 4.62124 7.44837 4.64956 7.51275C4.67639 7.57479 4.70719 7.6345 4.74246 7.69281C4.81053 7.80524 4.893 7.90786 4.99037 7.99929L4.99087 8.00023Z"
            fill="url(#paint1_linear_133_20)"
          />
          <path
            d="M8.52014 4.68631C9.61709 3.65631 11.1622 3.1637 12.7008 3.35403C14.2389 3.54435 15.5956 4.39522 16.3717 5.65707C17.1477 6.91891 17.254 8.44759 16.6603 9.7934C16.0666 11.1392 14.84 12.1496 13.3411 12.5265C13.5677 11.7321 13.5677 10.8952 13.3411 10.1008C13.1146 9.30639 12.669 8.58147 12.0494 8.00023C10.873 6.89559 9.69658 5.79095 8.52014 4.68631Z"
            fill="url(#paint2_linear_133_20)"
          />
          <path
            d="M8.52014 4.68632C9.69658 5.79096 10.873 6.8956 12.0494 8.00024C12.669 8.58195 13.1146 9.3064 13.3411 10.1008C13.5677 10.8953 13.5677 11.7321 13.3411 12.5266C13.9468 12.374 14.5161 12.116 15.0199 11.7662C15.1237 11.6939 15.224 11.6188 15.3214 11.539C15.3696 11.4993 15.4173 11.4592 15.464 11.4182C15.4873 11.3977 15.5102 11.3771 15.5331 11.3561C15.5519 11.3389 15.5703 11.3216 15.5887 11.3044C15.6712 11.2251 15.7432 11.1378 15.8043 11.0427C15.8684 10.9433 15.9191 10.8383 15.9588 10.7287C15.9797 10.6709 15.9976 10.6121 16.012 10.5524C16.0274 10.4899 16.0393 10.4274 16.0487 10.3635C16.0666 10.2389 16.0731 10.1153 16.0706 9.9898C16.0691 9.92263 16.0651 9.85545 16.0592 9.78828C16.0527 9.71784 16.0433 9.6474 16.0318 9.57743C16.007 9.42535 15.9717 9.27608 15.928 9.12773C15.8793 8.96353 15.8212 8.80259 15.7546 8.64399C15.702 8.51944 15.6448 8.39675 15.5832 8.27546C15.5172 8.14625 15.4466 8.01983 15.3721 7.89481C15.2101 7.62518 15.0283 7.36535 14.8316 7.11671C14.7402 7.00103 14.6458 6.88814 14.5479 6.77711C14.448 6.66376 14.3452 6.5532 14.2394 6.44544C14.0252 6.22713 13.8007 6.02047 13.5637 5.82408C13.5016 5.77277 13.439 5.72192 13.3754 5.67201C13.3123 5.62256 13.2492 5.57451 13.1847 5.52693C13.059 5.4341 12.9308 5.34453 12.7996 5.25823C12.5527 5.0959 12.2988 4.94755 12.0336 4.81367C11.7355 4.66346 11.4235 4.53192 11.102 4.43069C10.8323 4.34532 10.5521 4.28048 10.2689 4.24736C10.0886 4.22637 9.90623 4.21797 9.7244 4.22777C9.56393 4.23616 9.40446 4.25855 9.24945 4.29867C9.17791 4.31733 9.10786 4.33926 9.0393 4.36585C8.97323 4.39104 8.90964 4.41996 8.84754 4.45308C8.72781 4.51699 8.61851 4.59442 8.52114 4.68586L8.52014 4.68632Z"
            fill="url(#paint3_linear_133_20)"
          />
          <path
            d="M12.049 8.00024C13.146 9.03024 13.6706 10.481 13.4679 11.9257C13.2652 13.37 12.359 14.6439 11.0152 15.3726C9.67132 16.1012 8.04329 16.2011 6.61 15.6436C5.17672 15.0862 4.10064 13.9344 3.69922 12.527C4.54528 12.7397 5.43655 12.7397 6.28261 12.527C7.12867 12.3143 7.90071 11.8959 8.51973 11.3142C9.69616 10.2095 10.8726 9.10488 12.049 8.00024Z"
            fill="url(#paint4_linear_133_20)"
          />
          <path
            d="M12.049 8.00024C10.8726 9.10488 9.69616 10.2095 8.51973 11.3142C7.90021 11.8959 7.12867 12.3143 6.28261 12.527C5.43655 12.7397 4.54528 12.7397 3.69922 12.527C3.86167 13.0957 4.13641 13.6303 4.50901 14.1033C4.58602 14.2008 4.666 14.295 4.75096 14.3864C4.79319 14.4317 4.83591 14.4765 4.87963 14.5203C4.90149 14.5422 4.92335 14.5637 4.9457 14.5852C4.96409 14.6029 4.98247 14.6201 5.00085 14.6374C5.08531 14.7148 5.17821 14.7825 5.27956 14.8399C5.38538 14.9 5.49716 14.9476 5.61391 14.9849C5.67551 15.0045 5.73811 15.0213 5.8017 15.0349C5.86827 15.0493 5.93485 15.0605 6.00291 15.0694C6.13555 15.0862 6.26721 15.0922 6.40085 15.0899C6.47239 15.0885 6.54393 15.0848 6.61547 15.0792C6.69049 15.0731 6.7655 15.0642 6.84003 15.0535C7.00198 15.0302 7.16096 14.9971 7.31895 14.956C7.49382 14.9103 7.66522 14.8557 7.83413 14.7932C7.96678 14.7438 8.09744 14.6901 8.22661 14.6323C8.36423 14.5702 8.49886 14.504 8.632 14.434C8.91916 14.2819 9.19588 14.1112 9.46068 13.9265C9.58388 13.8406 9.70411 13.752 9.82235 13.6601C9.94308 13.5664 10.0608 13.4698 10.1756 13.3704C10.4081 13.1694 10.6282 12.9585 10.8373 12.736C10.892 12.6777 10.9461 12.6189 10.9993 12.5592C11.0519 12.5 11.1031 12.4407 11.1538 12.3801C11.2527 12.2621 11.348 12.1417 11.44 12.0186C11.6128 11.7867 11.7708 11.5483 11.9134 11.2992C12.0734 11.0193 12.2135 10.7264 12.3213 10.4246C12.4122 10.1713 12.4813 9.90817 12.5165 9.64227C12.5389 9.47294 12.5478 9.30174 12.5374 9.13101C12.5285 8.98033 12.5046 8.83059 12.4619 8.68505C12.442 8.61787 12.4187 8.5521 12.3903 8.48772C12.3635 8.42568 12.3327 8.36597 12.2974 8.30766C12.2294 8.19524 12.1469 8.09261 12.0495 8.00118L12.049 8.00024Z"
            fill="url(#paint5_linear_133_20)"
          />
          <path
            d="M8.52014 11.3137C7.42319 12.3437 5.87813 12.8363 4.33952 12.646C2.80141 12.4556 1.44463 11.6048 0.668623 10.3429C-0.107388 9.08108 -0.213704 7.5524 0.379979 6.20659C0.973662 4.86078 2.20027 3.85037 3.69914 3.47345C3.47259 4.26788 3.47259 5.10475 3.69914 5.89918C3.92568 6.6936 4.37132 7.41852 4.99083 7.99976C6.16727 9.1044 7.34371 10.209 8.52014 11.3137Z"
            fill="url(#paint6_linear_133_20)"
          />
          <path
            d="M8.5202 11.3137C7.34377 10.209 6.16733 9.1044 4.9909 7.99976C4.37138 7.41805 3.92574 6.6936 3.6992 5.89918C3.47266 5.10475 3.47266 4.26788 3.6992 3.47345C3.09359 3.62599 2.52425 3.88396 2.02049 4.23382C1.91666 4.30613 1.81631 4.38123 1.71893 4.461C1.67074 4.50065 1.62305 4.54077 1.57635 4.58182C1.553 4.60235 1.53015 4.62287 1.50729 4.64386C1.48841 4.66112 1.47003 4.67838 1.45165 4.69564C1.36918 4.77495 1.29714 4.86218 1.23604 4.95734C1.17195 5.0567 1.12128 5.16166 1.08153 5.27129C1.06066 5.32913 1.04278 5.38791 1.02837 5.44762C1.01297 5.51013 1.00105 5.57264 0.991609 5.63655C0.973724 5.7611 0.967265 5.88472 0.969749 6.0102C0.97124 6.07737 0.975214 6.14455 0.981176 6.21172C0.987634 6.28216 0.997074 6.3526 1.0085 6.42257C1.03334 6.57465 1.06861 6.72392 1.11233 6.87227C1.16102 7.03647 1.21915 7.19741 1.28572 7.35601C1.33838 7.48056 1.39551 7.60325 1.45712 7.72454C1.52319 7.85375 1.59374 7.98017 1.66826 8.10519C1.83022 8.37482 2.01205 8.63465 2.20878 8.88329C2.3002 8.99898 2.39459 9.11187 2.49246 9.22289C2.59232 9.33625 2.69516 9.4468 2.80098 9.55456C3.0151 9.77288 3.23966 9.97953 3.47663 10.1759C3.53873 10.2272 3.60133 10.2781 3.66492 10.328C3.72801 10.3774 3.79111 10.4255 3.85569 10.4731C3.98139 10.5659 4.10956 10.6555 4.24072 10.7418C4.48763 10.9041 4.7415 11.0524 5.00679 11.1863C5.30488 11.3365 5.61687 11.4681 5.9383 11.5693C6.20807 11.6547 6.48827 11.7195 6.77145 11.7526C6.95179 11.7736 7.13411 11.782 7.31595 11.7722C7.47641 11.7638 7.63589 11.7414 7.79089 11.7013C7.86243 11.6827 7.93248 11.6607 8.00104 11.6342C8.06712 11.609 8.13071 11.58 8.19281 11.5469C8.31254 11.483 8.42184 11.4056 8.51921 11.3141L8.5202 11.3137Z"
            fill="url(#paint7_linear_133_20)"
          />
          <path d="M8.52035 4.68785L4.99194 8.00092L8.52035 11.314L12.0488 8.00092L8.52035 4.68785Z" fill="black" />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_133_20"
            x1="3.14881"
            y1="6.49114"
            x2="11.245"
            y2="0.228083"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.31" stop-color="#3FBA9D" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_133_20"
            x1="3.58739"
            y1="6.18933"
            x2="12.3425"
            y2="1.68076"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.33" stop-color="#3FBA9D" />
            <stop offset="0.43" stop-color="#3AAE94" />
            <stop offset="0.6" stop-color="#2F907E" />
            <stop offset="0.83" stop-color="#1D605B" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_133_20"
            x1="10.1268"
            y1="2.95611"
            x2="15.7832"
            y2="11.2501"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.31" stop-color="#3FBA9D" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_133_20"
            x1="10.4487"
            y1="3.3685"
            x2="14.3635"
            y2="11.9912"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.33" stop-color="#3FBA9D" />
            <stop offset="0.43" stop-color="#3AAE94" />
            <stop offset="0.6" stop-color="#2F907E" />
            <stop offset="0.83" stop-color="#1D605B" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_133_20"
            x1="13.8917"
            y1="9.50886"
            x2="5.79546"
            y2="15.7719"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.31" stop-color="#3FBA9D" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_133_20"
            x1="13.4525"
            y1="9.81114"
            x2="4.69788"
            y2="14.3192"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.33" stop-color="#3FBA9D" />
            <stop offset="0.43" stop-color="#3AAE94" />
            <stop offset="0.6" stop-color="#2F907E" />
            <stop offset="0.83" stop-color="#1D605B" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_133_20"
            x1="6.91298"
            y1="13.0434"
            x2="1.25652"
            y2="4.74993"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.31" stop-color="#3FBA9D" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_133_20"
            x1="6.59111"
            y1="12.6315"
            x2="2.67638"
            y2="4.00876"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.33" stop-color="#3FBA9D" />
            <stop offset="0.43" stop-color="#3AAE94" />
            <stop offset="0.6" stop-color="#2F907E" />
            <stop offset="0.83" stop-color="#1D605B" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <clipPath id="clip0_133_20">
            <rect width="39" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default SmartContactLogoIcon
