import './styles/index.scss'
import './i18n'

import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import App from './App'
import BulletApp from './BulletApp'
import ButtonForPopupApp from './ButtonForPopupApp'
import BuyModelStandaloneApp from './BuyModelStandaloneApp'
import CarOrderForm from './carOrderForm/main/main'
import Bubble from './Components/Parts/Bubble'
import CreditCalcStandalone from './CreditCalcStandalone'
import { getCookie, logger, parseNumber } from './helpers'
import { getProjectName } from './helpers/app/detectors/project_name'
import Modal from './modal/Modal'
import { setCustomDataField } from './store/actions'
import store from './store/store'
import TradeInStandaloneApp from './TradeInStandaloneApp'

window['scLogger'] = logger

// window["@kodix"] = {
//     smartContract: {
//         showAuthForm: 1
//     }
// }

var dealersMap = {
  //"ид_дилера1": "ид_виджета1",
  '6015ca7acf84f600016f3d26': '60dc06a0b41983e00ff29e0b',
  '6017ab01cf84f600016f3d27': '60dc06d2b41983e00ff29e15',
}

//
// window['@kodix'] = {
//   smartContract: {
//     list: {
//       // widgetId: function (card) {
//       //     const json = JSON.parse(unescape(atob(card.querySelectorAll(".js-avn102-car-data-encoded")[0].value.substr(3))));
//       //
//       //     if( json['vin'] === "X4XCW89420LK67819" ) {
//       //         return "5f16d993a654ea26d1be9e03";
//       //     }
//       //
//       //     // return "5f186fc0a4e5a0bfc6b9d143"
//       //     return ""
//       // },
//       carIdsAndWidgetIds(cardData) {
//         let res = []
//         cardData.dealers_id.forEach(c => {
//           let widgetId = dealersMap[c]
//
//           if (widgetId) {
//             res.push({
//               identity: c,
//               widgetId,
//             })
//           }
//         })
//         return res
//       },
//     },
//   },
// }

window.SmartContractButtonsOnePlatformGenerator = text => {
  const original = document.querySelectorAll('.avn106-00-description__button .u107-00--full')[0]
  const clone = original.cloneNode(true)

  clone.lastChild.lastChild.innerText = text
  clone.style.marginTop = '5px'

  clone.classList.add('kdxsc-target')

  clone.removeAttribute('href')

  original.after(clone)

  return clone
}

window.SmartContractPriceOnePlatformGenerator = () => {
  try {
    return parseNumber(document.getElementsByClassName('avn106-00-description__price-current')[0].innerText)
  } catch (e) {
    return null
  }
}

window.InitSmartContractInPopup = config => {
  const widgetHtmlId = `kdxscPopup${config.type}`
  const element = document.getElementById(widgetHtmlId)
  const container = document.createElement('div')

  if (element) {
    ReactDOM.unmountComponentAtNode(element)
    element.remove()
  }

  container.id = widgetHtmlId
  document.body.append(container)

  ReactDOM.render(
    <Suspense fallback="loading">
      <React.StrictMode>
        <Provider store={store}>
          <ButtonForPopupApp config={config} />
        </Provider>
      </React.StrictMode>
    </Suspense>,
    container,
  )
}

window.InitSmartContractBullet = config => {
  const widgetHtmlId = 'kdxsc_bullet'
  const element = document.getElementById(widgetHtmlId)
  const container = document.createElement('div')

  if (element) {
    ReactDOM.unmountComponentAtNode(element)
    element.remove()
  }

  container.id = widgetHtmlId
  document.body.append(container)

  ReactDOM.render(
    <Suspense fallback="loading">
      <React.StrictMode>
        <Provider store={store}>
          <BulletApp config={config} />
        </Provider>
      </React.StrictMode>
    </Suspense>,
    container,
  )
}

window.InitSmartContractWidget = config => {
  const widgetHtmlId = 'kdxsc_panel'
  const element = document.getElementById(widgetHtmlId)
  const container = document.createElement('div')

  if (element) {
    ReactDOM.unmountComponentAtNode(element)
    element.remove()
  }

  container.id = widgetHtmlId
  document.body.append(container)

  ReactDOM.render(
    <Suspense fallback="loading">
      <React.StrictMode>
        <Provider store={store}>
          <App config={config} />
        </Provider>
      </React.StrictMode>
    </Suspense>,
    container,
  )
}

window.InitSmartContractCarOrderPopup = config => {
  const carOrderAppHtmlId = 'kdxsc_car_order'
  const element = document.getElementById(carOrderAppHtmlId)
  const container = document.createElement('div')

  if (element) {
    ReactDOM.unmountComponentAtNode(element)
    element.remove()
  }

  container.id = carOrderAppHtmlId
  document.body.append(container)

  ReactDOM.render(
    <Suspense fallback="loading">
      <React.StrictMode>
        <Provider store={store}>
          <Modal config={config} />
        </Provider>
      </React.StrictMode>
    </Suspense>,
    container,
  )
}

window.InitSmartContract = config => {
  window.InitSmartContractWidget(config)
  // window.InitStandalone('creditcalc', config)
  // window.InitStandalone('tradein', config)
}

window?.locator?.register('smartContract')

document.dispatchEvent(new CustomEvent('kodix:smartContract:initReady'))

window.InitSmartContractTooltip = (clonedElement, tooltipData) => {
  if (!clonedElement || !tooltipData) {
    return false
  }

  let KDXSC_tooltips_count = getCookie('KDXSC_tooltips') ? getCookie('KDXSC_tooltips') : 0

  document.cookie = `KDXSC_tooltips=${++KDXSC_tooltips_count}`

  if (getCookie('KDXSC_tooltips') > 3) {
    return false
  }

  const div = document.createElement('div')
  div.classList.add('kdxsc_tooltip')

  if (Array.isArray(clonedElement)) {
    ;[...clonedElement].forEach(el => el.after(div))
  } else {
    clonedElement.after(div)
  }

  const els = document.getElementsByClassName('kdxsc_tooltip')

  for (let item of els) {
    ReactDOM.render(
      <Suspense fallback="loading">
        <React.StrictMode>
          <Provider store={store}>
            <Bubble tooltipData={tooltipData} clonedElement={clonedElement} />
          </Provider>
        </React.StrictMode>
      </Suspense>,
      item,
    )
  }
}

window.InitCarOrderForm = (id, config) => initStandaloneCommon(id, config)

window.InitSmartBuyModelForm = config => initStandaloneCommon('kdxscBuyModel', config)

window.InitTradeInStandalone = config => initStandaloneCommon('kdxscTradeIn', config)

window.InitCreditCalcStandalone = config => initStandaloneCommon('kdxscCreditCalc', { ...config, type: 'credit' })

window.InitLeasingStandalone = config => initStandaloneCommon('kdxscLeasing', { ...config, type: 'leasing' })

const initStandaloneCommon = (id, config) => {
  let container = document.querySelector(`div#${id}`)

  if (id === 'kdxscBuyModel' && config?.targetElement?.nodeType === Node.ELEMENT_NODE) {
    container = config.targetElement
  }

  if (!container) {
    container = document.createElement('div')
    container.id = id + 'Container'

    const script = document.getElementById(id)
    script.after(container)
  }

  config.projectName = getProjectName()

  try {
    const parentElement = container.parentElement
    const parentElementBackground = window.getComputedStyle(parentElement, null).getPropertyValue('background-color')

    if (!parentElementBackground.includes('rgba')) {
      document.documentElement.style.setProperty(`--kdx_standalone_bg`, parentElementBackground)
    } else {
      document.documentElement.style.setProperty(`--kdx_standalone_bg`, 'white')
    }
  } catch (err) {
    console.error(err)
  }

  if (id === 'kdxscTradeIn' && container) {
    const defaultImage =
      getProjectName() === 'generic_v2' ? 'https://cdn.kodixauto.ru/media/image_original/67c0433646cc388bc566788a' : ''
    config.tradeInPicture = container.dataset.tradeInPicture || defaultImage
  }

  const renderStandaloneWidget = () => {
    // можно еще проверить ид = kdxscCarOrder
    if (id === 'avn_form') {
      const avnForm = document.querySelector('#avn_form')

      if (avnForm) {
        if (avnForm?.DATA_INSTANCE?.formInstance) {
          avnForm.DATA_INSTANCE.formInstance.active = false
        }
      }

      return (
        <div className="kdxsc-form-page">
          <CarOrderForm config={config} />
        </div>
      )
    }

    if (id === 'kdxscCreditCalc') {
      return <CreditCalcStandalone config={config} />
    }

    if (id === 'kdxscTradeIn') {
      return <TradeInStandaloneApp config={config} />
    }

    if (id === 'kdxscLeasing') {
      return <CreditCalcStandalone config={config} />
    }

    if (id === 'kdxscBuyModel') {
      return <BuyModelStandaloneApp config={config} />
    }
  }

  ReactDOM.render(
    <Suspense fallback="loading">
      <React.StrictMode>
        <Provider store={store}>{renderStandaloneWidget()}</Provider>
      </React.StrictMode>
    </Suspense>,
    container,
  )
}

window.SmartContractSetAdditionalData = (key, value) => {
  store.dispatch(setCustomDataField(key, value))
}
