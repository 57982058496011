import i18next from 'i18next'
import _uniq from 'lodash/uniq'

import { CREDIT_PARTNER, CREDIT_STANDALONE_SCREEN, FORM_SOURCE } from '../../constants'
import {
  benefitCalc,
  bootCredit,
  bootCreditMulti,
  bootLeasing,
  bootLeasingMulti,
  clearProtections,
  clearSmsAndCaptcha,
  closestTerm,
  createdEventPayload,
  createSmartContractWithProtection,
  creditOnlineApproval,
  creditOrLeasingStandaloneDealershipsSetter,
  creditStandaloneDealershipsCitySetter,
  creditStandaloneLoader,
  getDefaultCreditSettings,
  getDefaultLeasingSettings,
  loadCreditPrograms,
  loadLeasingPrograms,
  periodsFormatter,
} from '../../helpers'
import {
  createGetProgramsPayload,
  createSmartContractData,
  getCarModelData,
  isLeasingType,
} from '../helpers/credit_standalone'
import { CREDIT_STANDALONE } from '../types'
import { smartContractCreated } from './saved_sc'

export const setInitConfig = payload => ({
  type: CREDIT_STANDALONE.SET_INIT_CONFIG,
  payload,
})

export const setScreen = payload => ({
  type: CREDIT_STANDALONE.SET_SCREEN,
  payload,
})

export const setLoading = payload => ({
  type: CREDIT_STANDALONE.SET_LOADING,
  payload,
})

export const setError = payload => ({
  type: CREDIT_STANDALONE.SET_ERROR,
  payload,
})

export const setCarModel = payload => (dispatch, getState) => {
  if (payload === null) {
    dispatch({ type: CREDIT_STANDALONE.SET_CAR_MODEL, payload })
    return
  }

  const modelData = getCarModelData(payload)

  dispatch({ type: CREDIT_STANDALONE.SET_CAR_MODEL, payload: { ...payload, modelData } })

  const state = getState().credit_standalone
  const isLeasing = state.module.isLeasing
  const widgetId = state.config.widgetId

  benefitCalc(widgetId, payload.price, modelData)
    .then(response => {
      payload.benefitValue = isLeasing ? response.data.leasingBenefitValue : response.data.creditBenefitValue
      payload.condition = isLeasing ? response.data.leasingCondition : response.data.creditCondition
      dispatch({ type: CREDIT_STANDALONE.SET_CAR_BENEFITS, payload })

      window.dataLayer &&
        window.dataLayer.push({
          event: isLeasing ? 'leasingStandalone' : 'сreditCalcStandalone',
          Status: 'model',
          buttonName: payload.name,
          widget_id: widgetId,
          deal_id: '',
          eventValue: '',
        })
    })
    .catch(() => dispatch(setError(i18next.t('ccS.actions.autoCalculationDisabledTechError'))))

  if (!isLeasing) {
    dispatch(getDefaultCreditConditions())
  } else {
    dispatch(getDefaultLeasingConditions())
  }
}

export const setCarPrice = actionPayload => (dispatch, getState) => {
  const state = getState().credit_standalone

  const isLeasing = state.module.isLeasing

  if (isLeasing && actionPayload < 1000000) {
    actionPayload = 1000000
  }

  const processSetCarPrice = () => {
    const initFeePercent = Number(state.conditions.initFeePercent)
    const buybackPercent = Number(state.conditions.buybackPercent)
    const carPrice = Number(actionPayload)

    if ((initFeePercent > 0 || buybackPercent > 0) && carPrice) {
      return {
        ...state.conditions,
        carPrice: actionPayload,
        initFee: String((carPrice / 100) * initFeePercent),
        buyback: String((carPrice / 100) * buybackPercent),
      }
    }

    if (!carPrice) {
      return {
        ...state.conditions,
        carPrice: '0',
        initFee: '0',
        buyback: '0',
        initFeePercent: '0',
        buybackPercent: '0',
      }
    }

    return {
      ...state.conditions,
      carPrice: actionPayload,
    }
  }

  dispatch({
    type: CREDIT_STANDALONE.SET_CAR_PRICE,
    payload: processSetCarPrice(),
  })
}

export const setInitFee = actionPayload => (dispatch, getState) => {
  const state = getState().credit_standalone
  const defaultSettingsAvailable = state.config.defaultSettingsAvailable
  const term = state.conditions.term
  let allDefaultConditions = state.conditions.defaultConditions
  let closest = closestTerm(allDefaultConditions, term)
  let defaultConditions = allDefaultConditions?.[closest]
  const carPrice = Number(state.conditions.carPrice)

  let maxInitialFeePercentageValue = state.module.isLeasing ? 50 : 95
  let minInitialFeePercentageValue = 0

  if (defaultSettingsAvailable && defaultConditions && state.module.isLeasing) {
    if (defaultConditions.initialFeePercentMax) {
      maxInitialFeePercentageValue = defaultConditions.initialFeePercentMax
    }
    if (defaultConditions.initialFeePercentMin) {
      minInitialFeePercentageValue = defaultConditions.initialFeePercentMin
    }
  }

  const processSetInitFee = () => {
    if (!carPrice) {
      return state.conditions
    }
    const initFee = actionPayload
    const maxInitFeeValue = carPrice * (maxInitialFeePercentageValue / 100)
    const minInitFeeValue = carPrice * (minInitialFeePercentageValue / 100)

    // если число больше 95% от стоимости тачки
    if (initFee > maxInitFeeValue) {
      return {
        ...state.conditions,
        initFee: String(maxInitFeeValue),
        initFeePercent: maxInitialFeePercentageValue.toString(),
        buyback: '',
        buybackPercent: '0', // тут пока-что нормально, тк минимальный платеж от БМВ банка 0, но надо будет заменить
      }
    }

    // если число отсутствует ИЛИ МЕНЬШЕ МИНИМУМА
    if (!initFee || initFee < minInitFeeValue) {
      return {
        ...state.conditions,
        initFee: minInitFeeValue.toString() || '0',
        initFeePercent: minInitialFeePercentageValue.toString() || '0',
      }
    }

    // если первоначальный взнос выше остаточного платежа
    const initFeePercent = Math.round(initFee / (carPrice / 100))
    const buybackPercent = maxInitialFeePercentageValue - initFeePercent

    const maxSum = state.module.isLeasing ? 99 : 95

    if (maxSum - initFeePercent <= Number(state.conditions.buybackPercent)) {
      let buyback = maxInitFeeValue - initFee

      if (buyback === 0) {
        buyback = ''
      } else {
        buyback = String(buyback)
      }

      return {
        ...state.conditions,
        initFee: actionPayload,
        initFeePercent: String(initFeePercent),
        buyback: buyback,
        buybackPercent: String(buybackPercent),
      }
    }

    return {
      ...state.conditions,
      initFee: actionPayload,
      initFeePercent: String(initFeePercent),
    }
  }

  dispatch({
    type: CREDIT_STANDALONE.SET_INITFEE,
    payload: processSetInitFee(),
  })
}

export const setInitFeePercent = actionPayload => (dispatch, getState) => {
  const state = getState().credit_standalone

  const defaultSettingsAvailable = state.config.defaultSettingsAvailable
  const term = state.conditions.term
  let allDefaultConditions = state.conditions.defaultConditions
  let closest = closestTerm(allDefaultConditions, term)
  let defaultConditions = allDefaultConditions?.[closest]
  const carPrice = Number(state.conditions.carPrice)
  const buybackPercent = Number(state.conditions.buybackPercent)

  let maxInitialFeePercentageValue = state.module.isLeasing ? 50 : 95
  let minInitialFeePercentageValue = 0

  if (defaultSettingsAvailable && defaultConditions && state.module.isLeasing) {
    if (defaultConditions.initialFeePercentMax) {
      maxInitialFeePercentageValue = defaultConditions.initialFeePercentMax
    }
    if (defaultConditions.initialFeePercentMin) {
      minInitialFeePercentageValue = defaultConditions.initialFeePercentMin
    }
  }

  actionPayload = Number(actionPayload)
  const maxSum = state.module.isLeasing ? 99 : 95

  const setInitFee = () => {
    if (actionPayload > maxInitialFeePercentageValue) {
      return state.conditions.initFee
    }

    if (actionPayload < minInitialFeePercentageValue) {
      return state.conditions.initFee
    }

    return ((carPrice / 100) * actionPayload).toString()
  }

  const setBuyback = () => {
    if (actionPayload > maxSum - buybackPercent) {
      return (carPrice / 100) * (maxSum - actionPayload).toString()
    } else {
      return state.conditions.buyback
    }
  }

  const setBuybackPercent = () => {
    if (actionPayload > maxSum - buybackPercent) {
      return Math.round(maxSum - actionPayload).toString()
    } else {
      return state.conditions.buybackPercent
    }
  }

  const payload = {
    ...state.conditions,
    initFee: setInitFee(),
    initFeePercent: actionPayload,
    buyback: setBuyback(),
    buybackPercent: setBuybackPercent(),
  }

  dispatch({ type: CREDIT_STANDALONE.SET_INITFEE_PERCENT, payload })
}

export const setBuyback = actionPayload => (dispatch, getState) => {
  const state = getState().credit_standalone

  const processSetBuyback = () => {
    const buyback = Number(actionPayload)
    const carPrice = Number(state.conditions.carPrice)

    // если число больше 95% от стоимости тачки
    if (buyback > carPrice * 0.95) {
      return {
        ...state.conditions,
        buyback: String(carPrice * 0.95),
        buybackPercent: '95',
        initFee: '',
        initFeePercent: '0',
      }
    }

    // если число отсутствует
    if (!buyback) {
      return {
        ...state.conditions,
        buyback: '0',
        buybackPercent: '0',
      }
    }

    // если остаточный платёж выше первоначального взноса
    const buybackPercent = Math.round(buyback / (carPrice / 100))
    const initFeePercent = 95 - buybackPercent

    if (95 - buybackPercent <= Number(state.conditions.initFeePercent)) {
      let initFee = carPrice * 0.95 - buyback

      if (initFee === 0) {
        initFee = ''
      } else {
        initFee = String(initFee)
      }

      return {
        ...state.conditions,
        buyback: actionPayload,
        buybackPercent: String(buybackPercent),

        initFee: initFee,
        initFeePercent: String(initFeePercent),
      }
    }

    return {
      ...state.conditions,
      buyback: actionPayload,
      buybackPercent: String(buybackPercent),
    }
  }

  dispatch({ type: CREDIT_STANDALONE.SET_BUYBACK, payload: processSetBuyback() })
}

export const setBuybackPercent = actionPayload => (dispatch, getState) => {
  const state = getState().credit_standalone
  const isLeasing = getState().credit_standalone.module.isLeasing

  actionPayload = Number(actionPayload)

  const defaultSettingsAvailable = state.config.defaultSettingsAvailable
  const term = state.conditions.term
  let allDefaultConditions = state.conditions.defaultConditions
  let closest = closestTerm(allDefaultConditions, term)
  let defaultConditions = allDefaultConditions?.[closest]
  const carPrice = Number(state.conditions.carPrice)
  const initFeePercent = Number(state.conditions.initFeePercent)

  let maxPercent = state.module.isLeasing ? 50 : 95
  let minPercent = 0

  const maxSum = state.module.isLeasing ? 99 : 95

  if (defaultSettingsAvailable && defaultConditions && isLeasing) {
    if (defaultConditions.initialFeePercentMax) {
      maxPercent = defaultConditions.buybackPaymentPercentMax
    }
    if (defaultConditions.initialFeePercentMin) {
      minPercent = defaultConditions.buybackPaymentPercentMin
    }
  }

  const setBuyback = () => {
    if (actionPayload > maxPercent) {
      return state.conditions.buyback
    }

    if (actionPayload < minPercent) {
      return state.conditions.buyback
    }

    return ((carPrice / 100) * actionPayload).toString()
  }

  const setInitFee = () => {
    if (actionPayload > maxSum - initFeePercent) {
      return ((carPrice / 100) * (maxSum - actionPayload)).toString()
    } else {
      return state.conditions.initFee
    }
  }

  const setInitFeePercent = () => {
    if (actionPayload > maxSum - initFeePercent) {
      return String(Math.round(maxSum - actionPayload))
    } else {
      return state.conditions.initFeePercent
    }
  }

  const payload = {
    ...state.conditions,
    initFee: setInitFee(),
    initFeePercent: setInitFeePercent(),
    buyback: setBuyback(),
    buybackPercent: actionPayload,
  }

  dispatch({ type: CREDIT_STANDALONE.SET_BUYBACK_PERCENT, payload })
}

export const setTerm = payload => (dispatch, getState) => {
  if (payload == 12) {
    const is13Exists = getState().credit_standalone.conditions.possibleTerms.numericPeriods.includes(13)
    if (is13Exists) {
      payload = 13
    }
  }

  dispatch({ type: CREDIT_STANDALONE.SET_TERM, payload })
}

export const setPeronalDataFilled = payload => ({
  type: CREDIT_STANDALONE.SET_PERSONAL_DATA_FILLED,
  payload,
})

export const setPrograms = payload => ({
  type: CREDIT_STANDALONE.SET_PROGRAMS,
  payload,
})

export const setProgramsLoadedOnce = () => ({
  type: CREDIT_STANDALONE.SET_PROGRAMS_LOADED_ONCE,
})

export const selectProgram = payload => ({
  type: CREDIT_STANDALONE.SELECT_PROGRAM,
  payload,
})

export const getInitConfig = widgetId => (dispatch, getState) => {
  
  const isLeasing = getState().credit_standalone.module.isLeasing

  let loadData, loadDataMulti

  const getLoadDataFunc = isMulti => {
    if (isLeasing) {
      return isMulti ? bootLeasingMulti : bootLeasing
    }

    if (!isLeasing) {
      return isMulti ? bootCreditMulti : bootCredit
    }
  }

  const getData = widgetId =>
    loadData(widgetId)
      .then(response => {
        const hideBuybackPaymentForAllWidgets = response?.data?.disableBuybackPayment
        creditStandaloneLoader({ ...response?.data, widgetId, hideBuybackPaymentForAllWidgets })
      })
      .catch(() => dispatch(setError(i18next.t('ccS.actions.autoCalculationDisabledTechError'))))

  const getDataMulti = widgetIds =>
    loadDataMulti(widgetIds)
      .then(({ data }) => {
        if (Object.keys(data).length === 0) {
          dispatch(setError(i18next.t('ccS.actions.autoCalculationDisabledTechError')))
          return
        }

        const disableBuybackPayments = []

        // записываем ДЦ в стор
        const dealerships = Object.keys(data).map(item => {
          disableBuybackPayments.push(data[item].disableBuybackPayment)

          return {
            dealership: {
              name: data[item].dealership?.name || data[item].clientName,
              address: data[item].dealership?.address || '',
              city: data[item].dealership?.city || '',
            },
            showComplectations: data[item].showComplectations,
            showBrandInModelSelect: data[item].showBrandInModelSelect,
            clientName: data[item].clientName,
            widget_name: data[item].widgetName,
            widgetId: item,
            car: null,
            forCreditStandalone: {
              visual: data[item].visual,
              locale: data[item].locale,
              currency: data[item].currency,
              clientModels: data[item].clientModels,
              disableSmartContractLink: data[item].disableSmartContractLink,
              disableSmartContractLogo: data[item].disableSmartContractLogo,
              condition: data[item].condition,
              period: data[item].period,
              gapInsurance: data[item].gapInsurance,
              kaskoInsurance: data[item].kaskoInsurance,
              lifeInsurance: data[item].lifeInsurance,
              buybackPaymentPercentage: data[item].buybackPaymentPercentage,
              initialFeePercentage: data[item].initialFeePercentage,
              splitCarSelect: data[item]?.splitCarSelect,
              onlineApprove: data[item]?.onlineApprove,
              possiblePeriods: data[item]?.possiblePeriods,
              defaultCountryCode: data[item]?.defaultCountryCode,
              possibleCountryCodes: data[item]?.possibleCountryCodes,
              priceToolTipEnabled: data[item]?.priceToolTipEnabled,
              showProgramsBeforePersonalData: data[item]?.showProgramsBeforePersonalData,
              defaultSettingsAvailable: data[item]?.defaultSettingsAvailable,
              disableBuybackPayment: data[item]?.disableBuybackPayment,
            },
          }
        })

        const widgetsWithDisableBuybackPaymentField = disableBuybackPayments.filter(i => i === true)
        const hideBuybackPaymentForAllWidgets =
          widgetsWithDisableBuybackPaymentField.length === Object.keys(data).length

        creditOrLeasingStandaloneDealershipsSetter({
          data: dealerships,
          calcType: isLeasing ? 'leasing' : 'credit',
        })
        creditStandaloneDealershipsCitySetter({
          data: dealerships,
          calcType: isLeasing ? 'leasing' : 'credit',
        })

        // загружаем 1ый виджет

        const firstWidgetId = Object.keys(data)[0]
        const firstWidget = data[firstWidgetId]
        firstWidget['hideBuybackPaymentForAllWidgets'] = hideBuybackPaymentForAllWidgets

        creditStandaloneLoader(firstWidget)
      })
      .catch(() => dispatch(setError(i18next.t('ccS.actions.autoCalculationDisabledTechError'))))

  if (typeof widgetId == 'string') {
    loadData = getLoadDataFunc()
    getData(widgetId)
    return
  }

  if (Array.isArray(widgetId)) {
    const uniqueWidgets = _uniq(widgetId)

    if (uniqueWidgets.length > 1) {
      loadDataMulti = getLoadDataFunc(true)
      getDataMulti(JSON.stringify({ data: uniqueWidgets }))
      return
    } else {
      loadData = getLoadDataFunc()
      getData(uniqueWidgets[0])
      return
    }
  }
}

export const getPrograms = () => (dispatch, getState) => {
  const state = getState().credit_standalone

  const { carModelData } = state.conditions

  if (!carModelData.length || carModelData === 'unset') {
    return
  }

  const isLeasing = state.module.isLeasing

  const payload = createGetProgramsPayload(state, isLeasing)

  const widgetId = state.config.widgetId

  const isPersonalDataFilled = state.personal.filled
  const showProgramsBeforePersonalData = state.config.showProgramsBeforePersonalData

  dispatch(setLoading(true))
  dispatch(setError(null))

  let loadPrograms

  if (isLeasing) {
    loadPrograms = loadLeasingPrograms
  } else {
    loadPrograms = loadCreditPrograms
  }

  loadPrograms(widgetId, JSON.stringify({ data: payload }))
    .then(({ data, errors }) => {
      if (data) {
        if (isPersonalDataFilled || showProgramsBeforePersonalData) {
          dispatch(setProgramsLoadedOnce())
          dispatch(setScreen(CREDIT_STANDALONE_SCREEN.CREDIT_PROGRAMS))
        } else {
          dispatch(setScreen(CREDIT_STANDALONE_SCREEN.PERSONAL_DATA))
        }
      }

      if (data && !data.length) {
        dispatch(setPrograms([]))
        return
      }

      if (!data || errors) {
        dispatch(setPrograms([]))
        dispatch(setError(i18next.t('ccS.actions.autoCalculationDisabledTechError')))
        return
      }

      dispatch(setPrograms(data))

      if (data.length > 0) {
        dispatch(selectProgram(data[0]))
      }
    })
    .catch(() => dispatch(setError(i18next.t('ccS.actions.autoCalculationDisabledTechError'))))
    .finally(() => dispatch(setLoading(false)))
}

export const goToOnlineApproval = ({ uniqueId, token, smartContractUrl }) => dispatch => {
  if (!uniqueId && !token) {
    return
  }

  dispatch(setLoading(true))

  creditOnlineApproval(uniqueId, token)
    .then(r => {
      const partner = r?.data?.attributes?.finance?.onlineApprove?.partner
      const onlineApprovalUrl = r?.data?.attributes?.finance?.onlineApprove?.url

      if (partner === CREDIT_PARTNER.E_CREDIT && smartContractUrl) {
        let url = `${smartContractUrl}&target=e_credit_online_approval&screen=credit`
        window.open(url, '_blank')
      } else if (onlineApprovalUrl) {
        window.open(onlineApprovalUrl, '_blank')
      } else {
        dispatch(setError(i18next.t('ccS.actions.error')))
      }
    })
    .catch(e => {
      console.error(e)
      dispatch(setError(i18next.t('ccS.actions.error')))
    })
    .finally(() => {
      dispatch(setLoading(false))
    })
}

export const goToSmartContract = (withOnlineApprove = false) => async (dispatch, getState) => {
  const state = getState()

  const isLeasing = state.credit_standalone.module.isLeasing
  const formSource = isLeasing ? FORM_SOURCE.LEASING_STANDALONE : FORM_SOURCE.CREDIT_STANDALONE

  const widgetId = state.credit_standalone.config.widgetId
  const payload = createSmartContractData(state)

  dispatch(setLoading(true))
  dispatch(setError(null))

  await createSmartContractWithProtection(
    widgetId,
    {
      ticket_answer: state.form.values.captcha || state.form.values.smsCode,
      ticket_id: state.form.captchaSettings[formSource].ticketId || state.form.smsCodeSettings[formSource].ticketId,
    },
    JSON.stringify({ data: payload }),
  )
    .then(response => {
      if (response.errors) {
        dispatch(setSmartContract(null))
        dispatch(setError(i18next.t('ccS.actions.error')))
        return
      }

      if (withOnlineApprove) {
        dispatch(
          goToOnlineApproval({
            uniqueId: response?.data?.genericId?.toUpperCase() + '-' + response?.data?.id,
            token: response?.data?.token,
            smartContractUrl: response?.data?.smartContractUrl,
          }),
        )
      } else {
        if (response?.data?.smartContractUrl) {
          window.open(response.data.smartContractUrl, '_blank')
        }
      }

      window.dataLayer &&
        window.dataLayer.push({
          event: 'SmartContract_OnlineDeal',
          Status: isLeasing ? 'leasingStandalone' : 'сreditCalcStandalone',
          buttonName: 'Посмотреть',
          widget_id: widgetId,
          deal_id: `${response.data.genericId}-${response.data.id}`,
          eventValue: '',
        })

      dispatch(setSmartContract({ ...response.data, createdFromBackground: false }))
      dispatch(smartContractCreated(createdEventPayload(widgetId, payload, response.data)))
      clearSmsAndCaptcha()
      clearProtections(formSource)

      if (response?.data?.smartContractUrl) {
        dispatch(setError(i18next.t('ccS.actions.weSavedYourConditions')))
      } else {
        dispatch(setError(i18next.t('ccS.actions.managerWillContactYou')))
      }
    })
    .finally(() => dispatch(setLoading(false)))
}

export const setSmartContract = payload => ({
  type: CREDIT_STANDALONE.SET_SMART_CONTRACT,
  payload,
})

export const createSmartContractBackground = ({ buttonText }) => async (dispatch, getState) => {
  const state = getState()
  const widgetId = state.credit_standalone.config.widgetId
  const payload = createSmartContractData(state)

  const isLeasing = state.credit_standalone.module.isLeasing
  const formSource = isLeasing ? FORM_SOURCE.LEASING_STANDALONE : FORM_SOURCE.CREDIT_STANDALONE

  dispatch(setLoading(true))
  dispatch(setError(null))

  await createSmartContractWithProtection(
    widgetId,
    {
      ticket_answer: state.form.values.captcha || state.form.values.smsCode,
      ticket_id: state.form.captchaSettings[formSource].ticketId || state.form.smsCodeSettings[formSource].ticketId,
    },
    JSON.stringify({ data: payload }),
  )
    .then(response => {
      if (response.errors) {
        dispatch(setSmartContract(null))
        dispatch(setError(i18next.t('ccS.actions.error')))
        return
      }

      window.dataLayer &&
        window.dataLayer.push({
          event: 'SmartContract_Create',
          Status: isLeasing ? 'leasingStandalone' : 'сreditCalcStandalone',
          buttonName: buttonText,
          widget_id: widgetId,
          deal_id: `${response.data.genericId}-${response.data.id}`,
          eventValue: '',
        })

      dispatch(setSmartContract({ ...response.data, createdFromBackground: true }))
      dispatch(smartContractCreated(createdEventPayload(widgetId, payload, response.data)))
      clearSmsAndCaptcha()
      clearProtections(formSource)

      if (state.credit_standalone.config.showProgramsBeforePersonalData && response?.data?.smartContractUrl) {
        dispatch(setError(i18next.t('ccS.actions.weSavedYourConditions')))
      } else if (state.credit_standalone.config.showProgramsBeforePersonalData && !response?.data?.smartContractUrl) {
        dispatch(setError(i18next.t('ccS.actions.managerWillContactYou')))
      }
    })
    .finally(() => {
      dispatch(setLoading(false))
    })
}

export const setType = payload => ({ type: CREDIT_STANDALONE.SET_TYPE, payload })

export const setCreditOrLeasingStandaloneDealerships = ({ calcType, dealerships }) => ({
  type: CREDIT_STANDALONE.SET_DEALERSHIPS,
  calcType,
  dealerships,
})
export const setCreditStandaloneDealershipsCitySetter = ({ calcType, city }) => ({
  type: CREDIT_STANDALONE.SET_DEALERSHIPS_CITY,
  calcType,
  city,
})

export const setCreditOrLeasingStandaloneActiveDealership = ({ calcType, dealership }) => ({
  type: CREDIT_STANDALONE.SET_ACTIVE_DEALERSHIP,
  calcType,
  dealership,
})

export const getDefaultCreditConditions = () => (dispatch, getState) => {
  const state = getState()

  if (!state.credit_standalone.config.defaultSettingsAvailable) {
    return
  }

  const { carModelData, carPrice, carModelId } = state.credit_standalone.conditions

  const getDefaultSettingsPayload = {
    data: {
      modelData: carModelData,
      price: carPrice,
      productType: 'unknown',
      identity: carModelId,
    },
  }

  getDefaultCreditSettings(state.credit_standalone.config.widgetId, getDefaultSettingsPayload)
    .then(response => {
      if (response?.data) {
        dispatch({ type: CREDIT_STANDALONE.SET_DEFAULT_CONDITIONS, payload: response.data })
      }
    })
    .catch(e => console.error(e))
}

export const getDefaultLeasingConditions = () => (dispatch, getState) => {
  const state = getState()

  if (!state.credit_standalone.config.defaultSettingsAvailable) {
    return
  }

  const { carModelData, carPrice, carModelId } = state.credit_standalone.conditions

  const getDefaultSettingsPayload = {
    data: {
      modelData: carModelData,
      price: carPrice,
      productType: 'unknown',
      identity: carModelId,
    },
  }

  const months = {
    12: 'oneYear',
    13: 'oneYear',
    24: 'twoYears',
    36: 'threeYears',
    48: 'fourYears',
    60: 'fiveYears',
    72: 'sixYears',
    84: 'sevenYears',
    96: 'eightYears',
  }

  getDefaultLeasingSettings(state.credit_standalone.config.widgetId, getDefaultSettingsPayload)
    .then(response => {
      if (response?.data) {
        // устанавливаем периоды и ограничения
        let periodsForStore = {}
        for (let period in response.data) {
          if (response.data.hasOwnProperty(period)) {
            periodsForStore[months[period]] = period
          }
        }

        const possiblePeriods = periodsFormatter(periodsForStore)
        dispatch({ type: CREDIT_STANDALONE.SET_DEFAULT_CONDITIONS, payload: response.data })
        dispatch({ type: CREDIT_STANDALONE.SET_POSSIBLE_TERMS, payload: possiblePeriods })
      }
    })
    .catch(e => console.error(e))
}
