import { instanceIs } from '../../instance'

export const kodixUidSetter = () => {
  if (!window['_KDX_UID'] && !instanceIs('europe')) {
    // SC-446 добавляем kodix.pixel там где его нет, только если не европа
    const pixel = document.createElement('script')
    pixel.src = 'https://pixel.udpauto.ru/claim?nogtm'
    pixel.async = true
    document.head.append(pixel)
  }
}
