function SmartContractLogoIconDarkTheme() {
  return (
    <>
      <svg xmlns="http://www.w3.org/2000/svg" width="39" height="16" viewBox="0 0 39 16" fill="none">
        <g clip-path="url(#clip0_2001_9682)">
          <mask id="mask0_2001_9682" maskUnits="userSpaceOnUse" x="0" y="0" width="39" height="16">
            <path d="M39 0H0V16H39V0Z" fill="white" />
          </mask>
          <g mask="url(#mask0_2001_9682)">
            <path
              d="M19.654 10.6543C19.1952 10.4374 18.8411 10.1323 18.5911 9.73999C18.3411 9.34767 18.2158 8.89238 18.2158 8.37552V5.02148H19.1776V8.39324C19.1776 8.94043 19.3602 9.37006 19.7259 9.68214C20.0916 9.99422 20.5946 10.1505 21.236 10.1505C21.8774 10.1505 22.3819 9.99422 22.7511 9.68214C23.1198 9.37006 23.3039 8.94043 23.3039 8.39324V5.02148H24.2657V8.37552C24.2657 8.89285 24.139 9.34767 23.886 9.73999C23.6324 10.1323 23.2783 10.4374 22.823 10.6543C22.3678 10.8717 21.8386 10.9799 21.236 10.9799C20.6334 10.9799 20.1122 10.8717 19.654 10.6543Z"
              fill="white"
            />
            <path
              d="M28.1706 5.02148C29.312 5.02148 30.1792 5.26546 30.7723 5.75293C31.3653 6.24041 31.6621 6.95134 31.6621 7.88477V8.01866C31.6621 8.95256 31.3653 9.66162 30.7723 10.1459C30.1792 10.6305 29.312 10.8726 28.1706 10.8726H25.2178V5.02148H28.1706ZM30.6908 8.02752V7.87591C30.6908 7.19811 30.4775 6.68497 30.0509 6.33744C29.6243 5.98944 28.9945 5.81544 28.161 5.81544H26.1796V10.0792H28.1706C29.8507 10.0792 30.6908 9.39526 30.6908 8.02752Z"
              fill="white"
            />
            <path
              d="M36.3273 5.02148C37.0326 5.02148 37.5904 5.17776 38.0009 5.48983C38.4114 5.80191 38.6166 6.22268 38.6166 6.75215V6.85897C38.6166 7.38843 38.4114 7.80874 38.0009 8.12128C37.5904 8.43336 37.0326 8.58963 36.3273 8.58963H33.4032V10.8731H32.4414V5.02148H36.3273ZM37.2941 7.5503C37.528 7.38703 37.6453 7.15659 37.6453 6.85897V6.75215C37.6453 6.45499 37.528 6.22455 37.2941 6.06081C37.0602 5.89754 36.7312 5.81544 36.3082 5.81544H33.4032V7.79568H36.3082C36.7312 7.79568 37.0597 7.71404 37.2941 7.5503Z"
              fill="white"
            />
            <path
              d="M4.99097 8.00016C3.89402 6.97016 3.3694 5.51939 3.57209 4.07468C3.77479 2.63044 4.68096 1.35647 6.02482 0.627817C7.36868 -0.100834 8.99672 -0.200662 10.43 0.356789C11.8633 0.914239 12.9394 2.06599 13.3408 3.47338C12.4947 3.26066 11.6035 3.26066 10.7574 3.47338C9.91133 3.6861 9.1393 4.10454 8.52028 4.68625C7.34384 5.79088 6.16741 6.89552 4.99097 8.00016Z"
              fill="url(#paint0_linear_2001_9682)"
            />
            <path
              d="M4.99075 8.00029C6.16718 6.89565 7.34362 5.79101 8.52006 4.68637C9.13957 4.10466 9.91111 3.68623 10.7572 3.47351C11.6033 3.26079 12.4945 3.26079 13.3406 3.47351C13.1781 2.90486 12.9034 2.37027 12.5308 1.89725C12.4538 1.79976 12.3738 1.70553 12.2888 1.6141C12.2466 1.56885 12.2039 1.52406 12.1602 1.48021C12.1383 1.45829 12.1165 1.43683 12.0941 1.41537C12.0757 1.39765 12.0573 1.38039 12.039 1.36313C11.9545 1.28569 11.8616 1.21805 11.7602 1.16067C11.6544 1.10049 11.5426 1.05291 11.4259 1.01559C11.3643 0.996003 11.3017 0.97921 11.2381 0.965681C11.1715 0.95122 11.105 0.940025 11.0369 0.931161C10.9042 0.914368 10.7726 0.908304 10.639 0.910636C10.5674 0.912036 10.4959 0.915767 10.4243 0.921365C10.3493 0.92743 10.2743 0.936293 10.1998 0.947022C10.0378 0.970346 9.87882 1.00347 9.72084 1.04452C9.54596 1.09023 9.37456 1.14481 9.20565 1.20732C9.073 1.25677 8.94234 1.31041 8.81317 1.36826C8.67556 1.4303 8.54092 1.49654 8.40778 1.56651C8.12062 1.71859 7.8439 1.88932 7.57911 2.07405C7.4559 2.15988 7.33567 2.24852 7.21743 2.34041C7.09671 2.43418 6.97896 2.53074 6.8642 2.6301C6.6317 2.83116 6.41161 3.04201 6.20246 3.26452C6.14781 3.32283 6.09366 3.38161 6.0405 3.44132C5.98784 3.50056 5.93667 3.55981 5.88599 3.62045C5.78713 3.73847 5.69174 3.85883 5.59983 3.98198C5.42694 4.21382 5.26896 4.4522 5.12637 4.7013C4.9664 4.98119 4.8263 5.27414 4.7185 5.57596C4.62758 5.82926 4.55853 6.09236 4.52325 6.35826C4.5009 6.52759 4.49195 6.69879 4.50239 6.86953C4.51133 7.0202 4.53518 7.16994 4.5779 7.31549C4.59777 7.38266 4.62112 7.44843 4.64944 7.51281C4.67627 7.57485 4.70707 7.63456 4.74234 7.69287C4.81041 7.8053 4.89288 7.90792 4.99025 7.99935L4.99075 8.00029Z"
              fill="url(#paint1_linear_2001_9682)"
            />
            <path
              d="M8.52002 4.68692C9.61697 3.65692 11.1621 3.16431 12.7007 3.35464C14.2388 3.54496 15.5955 4.39583 16.3716 5.65768C17.1476 6.91952 17.2539 8.4482 16.6602 9.79401C16.0665 11.1398 14.8399 12.1502 13.341 12.5271C13.5676 11.7327 13.5676 10.8958 13.341 10.1014C13.1145 9.307 12.6689 8.58208 12.0493 8.00084C10.8729 6.8962 9.69646 5.79156 8.52002 4.68692Z"
              fill="url(#paint2_linear_2001_9682)"
            />
            <path
              d="M8.52002 4.68723C9.69646 5.79187 10.8729 6.89651 12.0493 8.00115C12.6689 8.58286 13.1145 9.30731 13.341 10.1017C13.5676 10.8962 13.5676 11.733 13.341 12.5275C13.9467 12.3749 14.516 12.1169 15.0198 11.7671C15.1236 11.6948 15.2239 11.6197 15.3213 11.5399C15.3695 11.5002 15.4172 11.4601 15.4639 11.4191C15.4872 11.3986 15.5101 11.378 15.533 11.357C15.5518 11.3398 15.5702 11.3225 15.5886 11.3053C15.6711 11.226 15.7431 11.1387 15.8042 11.0436C15.8683 10.9442 15.919 10.8392 15.9587 10.7296C15.9796 10.6718 15.9975 10.613 16.0119 10.5533C16.0273 10.4908 16.0392 10.4283 16.0486 10.3644C16.0665 10.2398 16.073 10.1162 16.0705 9.99071C16.069 9.92354 16.065 9.85636 16.0591 9.78919C16.0526 9.71875 16.0432 9.64831 16.0317 9.57834C16.0069 9.42626 15.9716 9.27699 15.9279 9.12864C15.8792 8.96444 15.8211 8.8035 15.7545 8.6449C15.7019 8.52035 15.6447 8.39766 15.5831 8.27637C15.5171 8.14716 15.4465 8.02074 15.372 7.89572C15.21 7.62609 15.0282 7.36626 14.8315 7.11762C14.7401 7.00194 14.6457 6.88905 14.5478 6.77802C14.4479 6.66467 14.3451 6.55411 14.2393 6.44635C14.0251 6.22804 13.8006 6.02138 13.5636 5.82499C13.5015 5.77368 13.4389 5.72283 13.3753 5.67292C13.3122 5.62347 13.2491 5.57542 13.1846 5.52784C13.0589 5.43501 12.9307 5.34544 12.7995 5.25914C12.5526 5.09681 12.2987 4.94846 12.0335 4.81458C11.7354 4.66437 11.4234 4.53283 11.1019 4.4316C10.8322 4.34623 10.552 4.28139 10.2688 4.24827C10.0885 4.22728 9.90611 4.21888 9.72428 4.22868C9.56381 4.23707 9.40434 4.25946 9.24933 4.29958C9.17779 4.31824 9.10774 4.34017 9.03918 4.36676C8.97311 4.39195 8.90952 4.42087 8.84742 4.45399C8.72769 4.5179 8.61839 4.59533 8.52102 4.68677L8.52002 4.68723Z"
              fill="url(#paint3_linear_2001_9682)"
            />
            <path
              d="M12.049 8C13.146 9.03 13.6706 10.4808 13.4679 11.9255C13.2652 13.3698 12.359 14.6437 11.0152 15.3724C9.67132 16.101 8.04329 16.2009 6.61 15.6434C5.17672 15.086 4.10064 13.9342 3.69922 12.5268C4.54528 12.7395 5.43655 12.7395 6.28261 12.5268C7.12867 12.3141 7.90071 11.8957 8.51973 11.314C9.69616 10.2093 10.8726 9.10464 12.049 8Z"
              fill="url(#paint4_linear_2001_9682)"
            />
            <path
              d="M12.049 8C10.8726 9.10464 9.69616 10.2093 8.51973 11.314C7.90021 11.8957 7.12867 12.3141 6.28261 12.5268C5.43655 12.7395 4.54528 12.7395 3.69922 12.5268C3.86167 13.0955 4.13641 13.6301 4.50901 14.1031C4.58602 14.2006 4.666 14.2948 4.75096 14.3862C4.79319 14.4315 4.83591 14.4763 4.87963 14.5201C4.90149 14.542 4.92335 14.5635 4.9457 14.585C4.96409 14.6027 4.98247 14.6199 5.00085 14.6372C5.08531 14.7146 5.17821 14.7823 5.27956 14.8397C5.38538 14.8998 5.49716 14.9474 5.61391 14.9847C5.67551 15.0043 5.73811 15.0211 5.8017 15.0347C5.86827 15.0491 5.93485 15.0603 6.00291 15.0692C6.13555 15.086 6.26721 15.092 6.40085 15.0897C6.47239 15.0883 6.54393 15.0846 6.61547 15.079C6.69049 15.0729 6.7655 15.064 6.84003 15.0533C7.00198 15.03 7.16096 14.9969 7.31895 14.9558C7.49382 14.9101 7.66522 14.8555 7.83413 14.793C7.96678 14.7436 8.09744 14.6899 8.22661 14.6321C8.36423 14.57 8.49886 14.5038 8.632 14.4338C8.91916 14.2817 9.19588 14.111 9.46068 13.9263C9.58388 13.8404 9.70411 13.7518 9.82235 13.6599C9.94308 13.5662 10.0608 13.4696 10.1756 13.3702C10.4081 13.1692 10.6282 12.9583 10.8373 12.7358C10.892 12.6775 10.9461 12.6187 10.9993 12.559C11.0519 12.4998 11.1031 12.4405 11.1538 12.3799C11.2527 12.2619 11.348 12.1415 11.44 12.0184C11.6128 11.7865 11.7708 11.5481 11.9134 11.299C12.0734 11.0191 12.2135 10.7262 12.3213 10.4244C12.4122 10.1711 12.4813 9.90793 12.5165 9.64203C12.5389 9.4727 12.5478 9.3015 12.5374 9.13077C12.5285 8.98009 12.5046 8.83035 12.4619 8.68481C12.442 8.61763 12.4187 8.55186 12.3903 8.48748C12.3635 8.42544 12.3327 8.36573 12.2974 8.30742C12.2294 8.195 12.1469 8.09237 12.0495 8.00094L12.049 8Z"
              fill="url(#paint5_linear_2001_9682)"
            />
            <path
              d="M8.52014 11.3129C7.42319 12.3429 5.87813 12.8355 4.33952 12.6452C2.80141 12.4548 1.44463 11.604 0.668623 10.3421C-0.107388 9.08029 -0.213704 7.55161 0.379979 6.2058C0.973662 4.85999 2.20027 3.84958 3.69914 3.47266C3.47259 4.26709 3.47259 5.10396 3.69914 5.89839C3.92568 6.69281 4.37132 7.41773 4.99083 7.99897C6.16727 9.10361 7.34371 10.2082 8.52014 11.3129Z"
              fill="url(#paint6_linear_2001_9682)"
            />
            <path
              d="M8.5202 11.3129C7.34377 10.2082 6.16733 9.10361 4.9909 7.99897C4.37138 7.41726 3.92574 6.69281 3.6992 5.89839C3.47266 5.10396 3.47266 4.26709 3.6992 3.47266C3.09359 3.6252 2.52425 3.88317 2.02049 4.23303C1.91666 4.30534 1.81631 4.38044 1.71893 4.46021C1.67074 4.49986 1.62305 4.53998 1.57635 4.58103C1.553 4.60156 1.53015 4.62208 1.50729 4.64307C1.48841 4.66033 1.47003 4.67759 1.45165 4.69485C1.36918 4.77416 1.29714 4.86139 1.23604 4.95655C1.17195 5.05591 1.12128 5.16087 1.08153 5.2705C1.06066 5.32834 1.04278 5.38712 1.02837 5.44683C1.01297 5.50934 1.00105 5.57185 0.991609 5.63576C0.973724 5.76031 0.967265 5.88393 0.969749 6.00941C0.97124 6.07658 0.975214 6.14376 0.981176 6.21093C0.987634 6.28137 0.997074 6.35181 1.0085 6.42178C1.03334 6.57386 1.06861 6.72313 1.11233 6.87148C1.16102 7.03568 1.21915 7.19662 1.28572 7.35522C1.33838 7.47977 1.39551 7.60246 1.45712 7.72375C1.52319 7.85296 1.59374 7.97938 1.66826 8.1044C1.83022 8.37403 2.01205 8.63386 2.20878 8.8825C2.3002 8.99819 2.39459 9.11108 2.49246 9.2221C2.59232 9.33546 2.69516 9.44601 2.80098 9.55377C3.0151 9.77209 3.23966 9.97874 3.47663 10.1751C3.53873 10.2264 3.60133 10.2773 3.66492 10.3272C3.72801 10.3766 3.79111 10.4247 3.85569 10.4723C3.98139 10.5651 4.10956 10.6547 4.24072 10.741C4.48763 10.9033 4.7415 11.0516 5.00679 11.1855C5.30488 11.3357 5.61687 11.4673 5.9383 11.5685C6.20807 11.6539 6.48827 11.7187 6.77145 11.7518C6.95179 11.7728 7.13411 11.7812 7.31595 11.7714C7.47641 11.763 7.63589 11.7406 7.79089 11.7005C7.86243 11.6819 7.93248 11.6599 8.00104 11.6334C8.06712 11.6082 8.13071 11.5792 8.19281 11.5461C8.31254 11.4822 8.42184 11.4048 8.51921 11.3133L8.5202 11.3129Z"
              fill="url(#paint7_linear_2001_9682)"
            />
            <path d="M8.52011 4.6875L4.9917 8.00057L8.52011 11.3137L12.0486 8.00057L8.52011 4.6875Z" fill="black" />
          </g>
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_2001_9682"
            x1="3.14881"
            y1="6.49108"
            x2="11.245"
            y2="0.228022"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.31" stop-color="#3FBA9D" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2001_9682"
            x1="3.58727"
            y1="6.18939"
            x2="12.3424"
            y2="1.68082"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.33" stop-color="#3FBA9D" />
            <stop offset="0.43" stop-color="#3AAE94" />
            <stop offset="0.6" stop-color="#2F907E" />
            <stop offset="0.83" stop-color="#1D605B" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_2001_9682"
            x1="10.1267"
            y1="2.95672"
            x2="15.7831"
            y2="11.2507"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.31" stop-color="#3FBA9D" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_2001_9682"
            x1="10.4486"
            y1="3.36941"
            x2="14.3634"
            y2="11.9921"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.33" stop-color="#3FBA9D" />
            <stop offset="0.43" stop-color="#3AAE94" />
            <stop offset="0.6" stop-color="#2F907E" />
            <stop offset="0.83" stop-color="#1D605B" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_2001_9682"
            x1="13.8917"
            y1="9.50862"
            x2="5.79546"
            y2="15.7717"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.31" stop-color="#3FBA9D" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_2001_9682"
            x1="13.4525"
            y1="9.8109"
            x2="4.69788"
            y2="14.319"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.33" stop-color="#3FBA9D" />
            <stop offset="0.43" stop-color="#3AAE94" />
            <stop offset="0.6" stop-color="#2F907E" />
            <stop offset="0.83" stop-color="#1D605B" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_2001_9682"
            x1="6.91298"
            y1="13.0426"
            x2="1.25652"
            y2="4.74914"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.31" stop-color="#3FBA9D" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <linearGradient
            id="paint7_linear_2001_9682"
            x1="6.59111"
            y1="12.6307"
            x2="2.67638"
            y2="4.00797"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#4DD1B5" />
            <stop offset="0.33" stop-color="#3FBA9D" />
            <stop offset="0.43" stop-color="#3AAE94" />
            <stop offset="0.6" stop-color="#2F907E" />
            <stop offset="0.83" stop-color="#1D605B" />
            <stop offset="1" stop-color="#0E363D" />
          </linearGradient>
          <clipPath id="clip0_2001_9682">
            <rect width="39" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  )
}

export default SmartContractLogoIconDarkTheme
