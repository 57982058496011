import { EVENT_TYPE_WIDGET_BENEFIT_SELECTED, EVENT_TYPE_WIDGET_BENEFIT_UNSELECTED } from '../constants'

const url = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8919'

const request = (method, path, payload, token = null) => {
  const options = {
    method,
    headers: {
      'X-Pixel': window['_KDX_UID'] || '',
      'Content-Type': 'application/json',
    },
  }

  if (payload) {
    if (typeof payload === 'string') {
      options.body = payload
    } else {
      options.body = JSON.stringify(payload)
    }
  }

  if (token) {
    options.headers = {
      'X-Auth-Token': token,
    }
  }

  return new Promise((resolve, reject) => {
    fetch(url + path, options)
      .then(response => {
        if (response.ok) {
          resolve(response.json())
        } else {
          response
            .json()
            .then(json => {
              const description = () => {
                if (!json || !json.errors) {
                  return JSON.stringify(json)
                }

                const errors = []

                for (const e of json.errors) {
                  errors.push(e.title)
                }

                return errors.join(', ')
              }

              const formattedError = `response error (status: ${response.status}; description: ${description()}`
              reject(new Error(formattedError))
            })
            .catch(e => {
              const formattedError = `response error (status: ${response.status}; description: unable to parse json due to ${e}`
              reject(new Error(formattedError))
            })
        }
      })
      .catch(reject)
  })
}

const requestWithoutProcessing = (path, payload, type = null) => {
  const options = {}

  if (payload) {
    if (typeof payload === 'string') {
      options.body = payload
    } else {
      options.body = JSON.stringify(payload)
    }
  }

  const request = fetch(url + path, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'X-Pixel': window['_KDX_UID'] || '',
    },
    body: options.body,
  })

  if (type === 'event') {
    return request
  }

  return request.then(response => {
    if (!response.ok) {
      return Promise.reject(response)
    }

    return response.json()
  })
}

export const pushEvent = ({
  eventType,
  widgetId,
  uniqueCode,
  smartContractId = '',
  customTags = {},
  benefitData,
  customData = {},
}) => {
  const pixel = window['_KDX_UID'] || ''

  let payload = {
    unique_code: String(uniqueCode),
    smart_contract_id: smartContractId,
    pixel: pixel,
    custom_tags: customTags,
    ...customData,
  }

  if ([EVENT_TYPE_WIDGET_BENEFIT_SELECTED, EVENT_TYPE_WIDGET_BENEFIT_UNSELECTED].includes(eventType)) {
    payload = {
      ...payload,
      value: benefitData?.value ? String(benefitData.value) : '',
      total_count: benefitData?.totalCount ? String(benefitData.totalCount) : '',
      position: benefitData?.position ? String(benefitData.position) : '',
    }
  }

  return requestWithoutProcessing(`/api/v1/push_event/${widgetId}/${eventType}`, payload, 'event')
}

export const bootSimpleForm = widgetId => {
  return request('GET', `/api/v1/light_boot/${widgetId}`)
}

export const bootTradeIn = widgetId => {
  return request('GET', `/api/v1/boot_trade_in/${widgetId}`)
}

export const bootTradeInMulti = payload => {
  return request('POST', `/api/v1/boot_trade_in`, payload)
}

export const boot = (widgetId, price, identity, modelData, productType) => {
  return request(
    'GET',
    `/api/v1/boot/${widgetId}?price=${price}&model_data=${modelData}&identity=${identity}&productType=${productType}`,
  )
}

export const bootBullet = widgetId => {
  return request('GET', `/api/v1/boot_bullet/${widgetId}`)
}

export const getBrands = widgetId => {
  return request('GET', `/api/v1/trade_in/${widgetId}/brands`)
}

export const getModels = (widgetId, brandId) => {
  return request('GET', `/api/v1/trade_in/${widgetId}/models?brand_id=${brandId}`)
}

export const getGenerations = (widgetId, brandId, modelId) => {
  return request('GET', `/api/v1/trade_in/${widgetId}/generations?brand_id=${brandId}&model_id=${modelId}`)
}

export const getBodies = (widgetId, brandId, modelId, generationId) => {
  return request(
    'GET',
    `/api/v1/trade_in/${widgetId}/bodies?brand_id=${brandId}&model_id=${modelId}&generation_id=${generationId}`,
  )
}

export const getModifications = (widgetId, brandId, modelId, generationId, bodyId) => {
  return request(
    'GET',
    `/api/v1/trade_in/${widgetId}/modifications?brand_id=${brandId}&model_id=${modelId}&generation_id=${generationId}&body_id=${bodyId}`,
  )
}

export const getColors = (widgetId, brandId) => {
  if (brandId) {
    return request('GET', `/api/v1/trade_in/${widgetId}/colors?brand_id=${brandId}`)
  } else {
    return request('GET', `/api/v1/trade_in/${widgetId}/colors`)
  }
}

export const getCities = (widgetId, query, limit = 10) => {
  return request('GET', `/api/v1/trade_in/${widgetId}/cities?query=${query}&limit=${limit}`)
}

export const getEngines = widgetId => {
  return request('GET', `/api/v1/trade_in/${widgetId}/engines`)
}

export const getGearBoxes = widgetId => {
  return request('GET', `/api/v1/trade_in/${widgetId}/gearboxes`)
}

export const getInteriorColors = widgetId => {
  return request('GET', `/api/v1/trade_in/${widgetId}/interior_colors`)
}

export const getInteriorTypes = widgetId => {
  return request('GET', `/api/v1/trade_in/${widgetId}/interior_types`)
}

export const calculateTradeIn = (widgetId, modificationId, cityId, year, owner, mileage, color, brandId, modelId) => {
  return request(
    'POST',
    `/api/v1/trade_in/${widgetId}/calculate?brand_id=${brandId}&model_id=${modelId}&modification_id=${modificationId}&city_id=${cityId}&year=${year}&owner=${owner}&mileage=${mileage}&color=${color}`,
  )
}

export const calculateTradeInByIdentity = (widgetId, payload) => {
  return request('POST', `/api/v1/trade_in/${widgetId}/calculate_by_identity`, payload)
}

export const createSmartContract = (widgetId, payments = false, payload) => {
  return requestWithoutProcessing(`/api/v2/initialize/${widgetId}${payments ? '?payments=y' : ''}`, payload)
}

export const createSmartContractWithProtection = (widgetId, getParams, payload) => {
  let params = []
  let query = ''

  if (getParams.payments) {
    params.push('payments=y')
  }
  if (getParams.ticket_answer) {
    params.push(`ticket_answer=${getParams.ticket_answer}`)
  }
  if (getParams.ticket_id) {
    params.push(`ticket_id=${getParams.ticket_id}`)
  }
  query = params.join('&')

  return requestWithoutProcessing(`/api/v2/initialize/${widgetId}${query ? `?${query}` : ''}`, payload)
}

export const goToPaymentUrl = (genericId, id) => {
  return requestWithoutProcessing(`/api/v1/smart_contracts/${genericId}-${id}/payment`)
}

export const goToPaymentFullUrl = (genericId, id, payload) => {
  return requestWithoutProcessing(`/api/v1/smart_contracts/${genericId}-${id}/full_payment`, payload)
}

export const creditOnlineApproval = (uniqueId, token) => {
  return request('POST', `/api/v1/smart_contracts/${uniqueId}/finance/credit/online_approve/start`, {}, token)
}

export const loadCreditPrograms = (widgetId, payload) => {
  return request('POST', `/api/v1/credit_programs/${widgetId}`, payload)
}

export const bootListUrl = payload => {
  return request('POST', `/api/v1/boot_batch`, payload)
}

export const bootCredit = widgetId => request('GET', `/api/v1/boot_credit/${widgetId}`)

export const bootCreditMulti = payload => request('POST', `/api/v1/boot_credit`, payload)

export const bootLeasing = widgetId => request('GET', `/api/v1/boot_leasing/${widgetId}`)

export const bootLeasingMulti = payload => request('POST', `/api/v1/boot_leasing`, payload)

export const benefitCalc = (widgetId, price, modelData) =>
  request('GET', `/api/v1/benefit_calc/${widgetId}?price=${price}&model_data=${modelData}`)

export const bootBenefit = (widgetId, params) => {
  return request('GET', `/api/v1/benefit_calc/${widgetId}?${params}`)
}

export const updateTradeInCar = (smartContractId, token, params) => {
  return request('PATCH', `/api/v1/smart_contracts/${smartContractId}/car`, params, token)
}

export const updateTradeInOwnerCarPrice = (uniqueId, token, value) => {
  return request('POST', `/api/v1/smart_contracts/${uniqueId}/trade_in/set_owner_car_price/${value}`, {}, token)
}

export const loadLeasingPrograms = (widgetId, payload) => {
  return request('POST', `/api/v1/leasing_programs/${widgetId}`, payload)
}

export const bootBatch2 = (widget_page_source = 'detail', payload) => {
  return request('POST', `/api/v2/boot_batch?widget_page_source=${widget_page_source}`, payload)
}

export const bootBuyModel = payload => {
  return request('POST', `/api/v1/boot_buy_model`, payload)
}

export const getBootSettings = payload => {
  return request('POST', `/api/v1/boot_settings`, payload)
}

export const getDefaultCreditSettings = (widgetId, payload) => {
  return request('POST', `/api/v1/credit/${widgetId}/default_settings`, payload)
}

export const getDefaultLeasingSettings = (widgetId, payload) => {
  return request('POST', `/api/v1/leasing/${widgetId}/default_settings`, payload)
}

export const refreshProtection = ticketId => {
  return request('GET', `/api/v1/verification_tickets/${ticketId}/refresh`)
}
